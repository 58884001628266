import React, { useState } from 'react'
import { s, colors, alpha } from '../../style'
import Logo from '../../components/Logo'
import Section from '../../components/Section'
import Button from '../../components/Button'
import { useLangContext } from '../../context/lang.context'

const ContactInfo = ({ data }) => {
  const { lang } = useLangContext()
  const [copied, setCopied] = useState(false)

  // const textToCopy = `Omida \n Adres \nNIP \nKRS \n`
  // .concat(data.map(({ title }) => title + `\n`))
  // .replace(/[,]/g, '')

  // prettier-ignore
  const onCopy = () => {
    if (copied) setCopied(false)
    else if (typeof navigator !== 'undefined' && navigator.clipboard)
      navigator.clipboard
        .writeText(
`
Omida Logistics Sp. z o.o.
aleja Grunwaldzka 472C, 80-309 Gdańsk
NIP: 5842739582
KRS: 0000539585
`
        )
        .then(() => setCopied(true))
        .catch((error) => setCopied(false))
  }

  return (
    <Section top mobile bottomSmall>
      <div css={sContact}>
        <div css={sTop}>
          <span css={sTitle}>
            Registration data of Omida Logistics Sp. z o.o.
          </span>
          {/* <div css={{ width: 'max-content' }} className='copy-button'>
            <Button
              dimmedWhite={!copied}
              dimmed={copied}
              action={onCopy}
              extraCss={{
                padding: '0.75rem 1rem',
                textTransform: 'uppercase',
                fontSize: '0.675rem',
                marginBottom: 0,
              }}>
              {copied
                ? lang === 'pl'
                  ? 'Skopiowano'
                  : 'Copied'
                : lang === 'pl'
                ? 'Skopiuj do schowka'
                : 'Copy to clipboard'}
            </Button>
          </div> */}
        </div>
        <div css={sLinks}>
          {data.map(({ title, link, icon, span }, id) => (
            <React.Fragment key={id}>
              {link ? (
                <a
                  href={link}
                  target='_blank'
                  rel='noreferrer noopener'
                  css={[
                    sLink,
                    span && {
                      [s.sm]: { gridColumn: '1 / 3' },
                      [s.md]: { gridColumn: '1 / 4' },
                    },
                  ]}>
                  {icon && icon()}
                  {title}
                </a>
              ) : (
                <div css={sLink}>{title}</div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Section>
  )
}

const sTop = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '2rem',
  [s.xs]: {
    flexDirection: 'column',
    '.copy-button': {
      width: '100%',
      marginTop: '2rem',
      marginBottom: 0,
    },
  },
}

const sContact = {
  borderRadius: '2rem',
  padding: '4rem',
  [s.sm_down]: {
    // borderRadius: '1rem',
    padding: '2rem',
  },
  [s.xs]: {
    padding: '2rem 1.5rem 2rem',
  },
  background: `linear-gradient(to bottom right, ${colors.greyDark}, ${colors.greyDarker})`,
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
}

const sTitle = {
  fontSize: '1.5rem',
  fontWeight: 700,

  [s.sm_down]: {
    paddingLeft: '2.25rem',
  },
  [s.md]: {
    paddingLeft: '2rem',
  },
}

const sLinks = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '2rem',

  [s.sm_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [s.xs]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '1.5rem',
  },

  'a:hover': {
    color: colors.brandLight,
    backgroundColor: colors.white.concat(alpha[16]),
  },

  'div:hover': {
    backgroundColor: colors.white.concat(alpha[16]),
  },
}

const sLink = {
  transition: 'color 0.2s ease-out, background-color 0.2s ease-out',
  padding: '1.5rem 2rem',
  borderRadius: '1rem',
  backgroundColor: colors.greyDark,
  fontWeight: 700,
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',

  [s.xs]: {
    padding: '1rem 1rem 1rem 1.5rem',
  },

  svg: {
    width: '1rem',
    height: '1rem',
    marginRight: '1rem',
  },
}

export default ContactInfo
