import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import GoogleMapReact from 'google-map-react'
import { useLangContext } from '../../context/lang.context'
import { colors, s } from '../../style'
import LocationOmida from '../../assets/LocationOmida'
import Section from '../../components/Section'
import Headline from '../../components/Headline'
import TileContact from '../../components/TileContact'

const content = {
  departmentsTitle: {
    pl: 'Działy',
    en: 'Departments',
    zh: '部门',
  },
  officesTitle: {
    pl: 'Oddziały',
    en: 'Offices',
  },
  departments: [
    {
      title: {
        pl: 'Biuro Obsługi Klienta',
        en: 'Customer Service Office',
        zh: '客户服务部',
      },
      email: 'hello@omidatrade.pl',
      phone: '+48 799 061 967 or +48 535 871 468',
      image: 'customers',
    },
    {
      title: {
        pl: 'Dział Dokumentacji',
        en: 'Documentation Department',
        zh: '单证部',
      },
      email: 'dokumenty@omida.pl',
      desc: {
        pl: ['faktury', 'dokumentacja przewozowa', 'noty'],
        en: ['invoice', 'shipping documentation', 'notes'],
        zh: ['发票', '单证', '票据'],
      },
      image: 'documentation',
    },
    {
      title: {
        pl: 'Dział Rozliczeń',
        en: 'Billing Department',
        zh: '收费部',
      },
      email: 'rozliczenia@omida.pl',
      desc: {
        pl: ['płatności za faktury'],
        en: ['payment for invoices'],
        zh: ['发票付款'],
      },
      image: 'billing',
    },
    {
      title: {
        pl: 'Dział Windykacji',
        en: 'Debt Collection Department',
        zh: '款项催收部',
      },
      email: 'windykacja@omida.pl',
      desc: {
        pl: ['wezwanie do zapłaty'],
        en: ['demand for payment'],
        zh: ['要求付款'],
      },
      image: 'collection',
    },
    {
      title: {
        pl: 'Dział Administracji',
        en: 'Administration Department',
        zh: '行政部',
      },
      email: 'administracja@omida.pl',
      image: 'administration',
    },
    {
      title: {
        pl: 'Dział Prawny',
        en: 'Legal Department',
        zh: '法律部',
      },
      email: 'szkody@omida.pl',
      image: 'legal',
    },
    {
      title: {
        pl: 'Dział Rekrutacji',
        en: 'Recruitment Department',
        zh: '招聘部',
      },
      email: 'hr@omida.pl',
      image: 'hr',
    },
    {
      title: {
        pl: 'Dział Marketingu',
        en: 'Marketing Department',
        zh: '市场部',
      },
      email: 'marketing@omida.pl',
      image: 'marketing',
    },
    {
      title: {
        pl: 'Dział IT',
        en: 'IT Department',
        zh: '信息技术部',
      },
      email: 'it@omida.pl',
      image: 'it',
    },
  ],
}

const MapOffices = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      children: allContentfulDepot(
        sort: { fields: city }
        filter: { city: { ne: null } }
      ) {
        nodes {
          city
          slug
          node_locale
          coords {
            lat
            lon
          }
          address
          noCreatePage
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
      customers: file(
        absolutePath: { regex: "/images/branches/customers.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      documentation: file(
        absolutePath: { regex: "/images/intros/polityka-firmy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      billing: file(absolutePath: { regex: "/images/branches/billing.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      collection: file(
        absolutePath: { regex: "/images/branches/collection.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      administration: file(
        absolutePath: { regex: "/images/branches/administration.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      legal: file(absolutePath: { regex: "/images/branches/legal.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      hr: file(absolutePath: { regex: "/images/branches/hr.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      marketing: file(
        absolutePath: { regex: "/images/branches/marketing.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      it: file(absolutePath: { regex: "/images/branches/it.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  
  const links = query.children.nodes
  .filter((node) => node.node_locale === lang)
  .filter((node) => node.slug != null)
  .map((node) => ({
    link: node.noCreatePage ? node.slug.slice(0, -2) : node.slug,
    title: node.city,
    image: node.image,
    lat: node.coords.lat,
    lon: node.coords.lon,
    email: node.email,
    phone: node.phone,
    address: node.address,
  }))
  
  // console.log(query.children.nodes);
  const mapConfig = {
    bootstrapURLKeys: {
      key: process.env.GATSBY_GOOGLE_API,
    },
    defaultCenter: {
      lat: 52.33,
      lng: 18.92,
    },
    defaultZoom: 6,
    yesIWantToUseGoogleMapApiInternals: true,
  }

  return (
    <Section top mobile bottomSmall>
    {/* <Section css={sSection}> */}
      {/* <div css={sMapInner}>
        <GoogleMapReact {...mapConfig}>
          {links.map(({ lat, lon }, id) => (
            <LocationOmida key={id} lat={lat} lng={lon} />
          ))}
        </GoogleMapReact>
      </div> */}
      <div css={sOuter} id='oddzialy'>
        <Headline h={2} large gradient>
          {content.departmentsTitle[lang]}
        </Headline>
        <div css={sList}>
          {content.departments.map(
            ({ title, email, phone, desc, image }, id) => (
              <TileContact
                key={id}
                title={title[lang]}
                subtitles={desc?.[lang]}
                email={email}
                phone={phone}
                image={query[image]}
              />
            )
          )}
        </div>
      </div>
    </Section>
  )
}

const sSection = {
  padding: '4rem',
  display: 'flex',
  position: 'relative',
  top: 0,
  left: 0,
  [s.sm_down]: {
    flexDirection: 'column',
    padding: '2rem',
  },
  [s.xs]: {
    padding: '0rem 1rem 2rem',
  },
}

// const sMapInner = {
//   borderRadius: '1rem',
//   overflow: 'hidden',

//   backgroundColor: colors.greyLighter,
//   [s.sm_down]: {
//     width: '100%',
//     height: 'calc(100vw - 4rem)',
//   },
//   [s.md]: {
//     borderRadius: '2rem',
//     maxWidth: '50%',
//     flexBasis: '50%',
//     height: 'calc(100vh - 8rem - 4rem)',
//     position: 'sticky',
//     top: '8rem',
//   },
// }

const sOuter = {
  width: '100%',
}

// const sOuter = {
//   [s.md]: {
//     marginLeft: '4rem',
//     maxWidth: '50%',
//     flexBasis: '50%',
//   },
//   [s.sm_down]: {
//     marginTop: '4rem',
//   },
// }

const sList = {
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: '1fr',
  margin: '2rem 0 4rem',
  [s.md]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [s.lg]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [s.xl]: {
    gap: '2rem',
  },
}

export default MapOffices
