import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import ProductTeam from '../sections/product/ProductTeam'

const TransportTeam = () => {
  const { lang } = useLangContext()
  const { team } = useStaticQuery(graphql`
    {
      team: allContentfulZespol(sort: { order: ASC, fields: createdAt }) {
        nodes {
          teamMemberEmail
          teamMemberLinkedIn
          teamMemberName
          teamMemberPhone
          teamMemberPosition
          teamMemberBio {
            childMarkdownRemark {
              html
            }
          }
          id
          lang {
            slug
          }
          teamMemberImage {
            gatsbyImageData(layout: CONSTRAINED, width: 1000)
          }
          teamCategory
          teamOrder
        }
      }
    }
  `)

  const transportTeam = team?.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => node.teamCategory === false)
    .map((node) => ({
      teamMemberName: node.teamMemberName,
      teamMemberPosition: node.teamMemberPosition,
      teamMemberBio: node.teamMemberBio,
      teamMemberEmail: node.teamMemberEmail,
      teamMemberPhone: node.teamMemberPhone,
      teamMemberLinkedIn: node.teamMemberLinkedIn,
      teamMemberImage: node.teamMemberImage,
      teamOrder: node.teamOrder,
    })).sort((a, b) => a.teamOrder - b.teamOrder);

  console.log(transportTeam);
 
  const title = {
    pl: 'Transport kontenerów',
    en: 'Container transport',
    zh: '集装箱运输'
  }

  return (
    <>
      {transportTeam.length > 0 && (
        <ProductTeam team={transportTeam} title={title[lang]} isContact />
      )}
    </>
  )
}
export default TransportTeam
