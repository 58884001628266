import { Link, navigate } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { colors, alpha, s } from '../style'
import alt from '../content/alt'

const TileContact = ({
  title,
  subtitles,
  email,
  phone,
  address,
  link,
  image,
}) => {
  return (
    <div
      css={[
        sContainer,
        link && {
          cursor: 'pointer',
          '&:hover': { '.title': { color: colors.brandLight } },
        },
      ]}
      onClick={() => link && navigate(`/${link}/`)}
      onKeyPress={() => link && navigate(`/${link}/`)}
      tabIndex={0}
      role='button'>
      <GatsbyImage
        className='image'
        image={image && getImage(image)}
        alt={title + alt}
        style={sImageStyle}
        imgStyle={sImageImgStyle}
      />
      <div css={sDimmer} className='dimmer' />
      <div css={sText}>
        {link ? (
          <Link className='title' to={`/${link}/`} css={sTitleOffice}>
            {title}
          </Link>
        ) : (
          <a href={`mailto:${email}`} className='title' css={sTitleDepartment}>
            {title}
          </a>
        )}
        {subtitles && (
          <div css={sSubtitles}>
            {subtitles.map((text, id) => (
              <React.Fragment key={id}>
                {id !== 0 && ` • `}
                <span>{text}</span>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <div css={sLinks}>
        {phone && <a href={`tel:${phone}`}>{phone}</a>}
        {email && (
          <a href={`mailto:${email}`} name='email'>
            {email}
          </a>
        )}
        {address && (
          <a
            href={`https://www.google.pl/maps/search/${address}`}
            target='_blank'
            rel='noreferrer noopener'
            name='address'>
            {address}
          </a>
        )}
      </div>
    </div>
  )
}

const sTitleDepartment = {
  // ...sTextGradient,
  fontSize: '1.25rem',
  fontWeight: 900,
}

const sTitleOffice = {
  fontSize: '1.5rem',
  textShadow: `2px 2px 8px ${colors.text}`,
  fontWeight: 700,
}

const sContainer = {
  backfaceVisibility: 'hidden',
  transform: 'translate3d(0, 0, 0)',

  position: 'relative',
  top: 0,
  left: 0,
  background: `linear-gradient(to bottom right, ${colors.greyDark.concat(
    alpha[60]
  )}, ${colors.greyDarker})`,
  padding: '2rem 2rem',
  borderRadius: '1rem',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  minHeight: '10rem',
  // marginBottom: '2rem',
  [s.xs]: {
    padding: '2rem 2rem',
  },

  '.title': {
    letterSpacing: '-0.05em',
    position: 'relative',
    top: 0,
    left: 0,
    zIndex: 2,
    transition: 'all 0.2s ease-in-out',
  },

  '.image': {
    transform: 'scale(1)',
  },
  '&:hover': {
    '.image': { transform: 'scale(1.25)', filter: 'brightness(1.1)' },
  },
}

const sLinks = {
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',

  a: {
    fontSize: '0.875rem',
    marginTop: '0.5rem',
    fontWeight: 700,
    // marginLeft: '1rem',
    textAlign: 'right',
    letterSpacing: '-0.05em',

    '&:hover': {
      color: colors.brandLight,
    },
  },
  'a[name="email"]': {
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  'a[name="address"]': {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: 0,
  },
}

const sText = {
  position: 'relative',
  top: 0,
  left: 0,
}

const sImageStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  transition: 'all 0.2s ease-in-out',
  borderRadius: '1rem',
}

const sImageImgStyle = {
  objectFit: 'cover',
  objectPosition: 'center center',
  borderRadius: '1rem',
}

const sDimmer = {
  borderRadius: '1rem',
  // opacity: 0.5,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(to top left, 
    ${colors.greyDarker.concat(alpha[100])} 0%, 
    ${colors.greyDarker.concat(alpha[90])} 25%, 
    ${colors.greyDarker.concat(alpha[80])} 50%,  
    ${colors.greyDarker.concat(alpha[50])} 75%,  
    ${colors.greyDarker.concat(alpha[0])} 100%)
    `,
  transition: 'opacity 0.2s ease-in-out',
}

const sSubtitles = {
  fontSize: '0.75rem',
  opacity: 0.6,
}

export default TileContact
